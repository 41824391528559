const baseUrl = process.env.NEXT_PUBLIC_API_URL;

export const API_ROUTES = {
  login_with_email: baseUrl + '/auth/login/',
  login_with_facebook: baseUrl + '/oauth2/facebook/callback/',
  login_with_google: baseUrl + '/oauth2/google/callback/',
  signup_with_email: baseUrl + '/auth/signup/',
  send_link_to_email: baseUrl + '/auth/request_reset_email/',
  verify_user: baseUrl + '/auth/verify_user/',
  reset_password: baseUrl + '/auth/password_reset_complete/',
  user_profile: baseUrl + '/user/',
  get_bucket_list: baseUrl + '/bucket/',
  create_bucket: baseUrl + '/bucket/',
  delete_bucket: baseUrl + '/bucket/',
  save_post_into_bucket: baseUrl + '/post/react/like/',
  get_articles_by_category: baseUrl + '/home/all_articles_by_category/',
  get_relative_posts: baseUrl + '/home/related_articles/',
  get_article_detail: baseUrl + '/post/post_details/',
  search_articles: baseUrl + '/home/search/',
  get_articles_by_tag: baseUrl + '/home/tag/',
  get_banner_articles: baseUrl + '/home/banner_articles/',
  get_latest_articles: baseUrl + '/home/latest_article/',
  get_destinations: baseUrl + '/home/destination_category/',
  get_recipe_articles: baseUrl + '/home/recipes/',
  get_halal_food_articles: baseUrl + '/home/halal_food_guides/',
  get_itinerary_articles: baseUrl + '/home/itinerary/',
  get_accommodation_articles: baseUrl + '/home/accommodations/',
  get_budget_travel_articles: baseUrl + '/home/budget_travel/',
  get_honey_moon_articles: baseUrl + '/home/honey_moon/',
  get_beach_holiday_articles: baseUrl + '/home/beach_holidays/',
  get_sitemap: baseUrl + '/home/sitemap',

  // tracking
  post_external_link: baseUrl + '/api/statistic/external_link/',
  tracking_article_listing: baseUrl + '/api/statistic/article_listing/',
  tracking_external_link: baseUrl + '/api/statistic/external_link/',
  tracking_branded_placement:
    baseUrl + '/api/statistic/branded_placement_block/',
  tracking_click_url_on_branded_placement:
    '/api/statistic/branded_placement_block/',

  get_all_categories: baseUrl + '/api/tribes/category/',
  get_homepage_data: baseUrl + '/home/homepage/',

  // Destination
  get_destination_detail: baseUrl + '/destination/destination_detail/',
  get_prayer_timings_destination: baseUrl + '/destination/prayer_times/',
  get_destination_explores: baseUrl + '/destination/explore/',

  // Listing
  get_listing_detail: baseUrl + '/api/tribes/venue-detail/',
  get_listing_reviews: baseUrl + '/api/tribes/venue-review/',
  get_venues: baseUrl + '/api/tribes/get-venue/',
  add_favourite_venue: baseUrl + '/api/tribes/favourites-venue/',
  remove_favourited_venue: baseUrl + '/api/tribes/favourites-venue/',
  get_venue_deals: baseUrl + '/api/tribes/venue-deal/',
  get_all_listings: baseUrl + '/api/tribes/get-all-venues/',
  get_all_articles: baseUrl + '/api/tribes/get-all-articles/',

  // Common
  upload_file: baseUrl + '/api/tribes-utils/upload-file/',
  get_country_destinations: baseUrl + '/api/tribes/get-destinations/',
  get_categories: baseUrl + '/api/tribes/get-categories/',

  // Review
  submit_venue_review: baseUrl + '/api/tribes/create-review-venue/',
  post_review_upvote: baseUrl + '/api/tribes/upvote-venue-review/',
  remove_review_upvote: baseUrl + '/api/tribes/upvote-venue-review/',

  // Dashboard
  get_dashboard_statistic: baseUrl + '/api/statistic/get_dashboard/',
  get_dashboard_article_statistic:
    baseUrl + '/api/statistic/get_data_ga4_article/',
  get_dashboard_listing_statistic:
    baseUrl + '/api/statistic/get_data_ga4_listing/',

  //login
  login_with_email_otp: baseUrl + '/auth/login-with-email/',
  login_with_phone_otp: baseUrl + '/auth/login-with-phone/',
  verify_email_otp: baseUrl + '/auth/confirm-login-with-email/',
  list_country: baseUrl + '/list_country/',
  list_city: baseUrl + '/list_city/',
  get_user: baseUrl + '/auth/get-info-user/',
  update_user: baseUrl + '/auth/update-info-user/',
  personalization_user: baseUrl + '/auth/personalization-user/',

  //profiles
  get_profile_user: baseUrl + '/auth/profile-detail/',
  get_profile_favourites_upvoted: baseUrl + '/auth/profile-favourites-upvote/',
  get_profile_review: baseUrl + '/auth/profile-review/',
  verify_phone_otp_login: baseUrl + '/auth/verify-otp-login/',
  get_profile_favourites_blogpage:
    baseUrl + '/auth/profile-favourites-article/',
  get_profile_favourites_venue: baseUrl + '/auth/profile-favourites-venue/',
  remove_favourited_blogpage: baseUrl + '/api/tribes/favourites-blogpage/',

  //home
  get_homepage_header_data: baseUrl + '/home/homepage_header/',
  get_homepage_body_data: baseUrl + '/home/homepage_body/',

  //landing
  get_landing_page_data: baseUrl + '/home/landing_detail/',

  //privacy
  get_privacy_policy_page: baseUrl + '/profile/standard_page_detail/',
};
