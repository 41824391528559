import { API_ROUTES } from 'utils/apiRoutes';
import { api } from 'utils/axios.config';

export const loginWithEmail = (model) => {
  const url = API_ROUTES.login_with_email;
  return api.post(url, model);
};

export const verifyUser = (model) => {
  const url = API_ROUTES.verify_user;
  return api.post(url, model);
};

export const getUserProfile = (userId) => {
  const url = API_ROUTES.user_profile + `${userId}/`;
  return api.get(url);
};

export const signupWithEmail = (model) => {
  const url = API_ROUTES.signup_with_email;
  return api.post(url, model);
};

export const submitSendLinkToEmail = (model) => {
  const url = API_ROUTES.send_link_to_email;
  return api.post(url, model);
};

export const resetPassword = (model) => {
  const url = API_ROUTES.reset_password;
  return api.patch(url, model);
};

export const getBucketList = (userId) => {
  const url = API_ROUTES.get_bucket_list + `?user=${userId}`;
  return api.get(url);
};

export const createBucket = (model) => {
  const url = API_ROUTES.create_bucket;
  return api.post(url, model);
};

export const savePostIntoBucket = (model) => {
  const url = API_ROUTES.save_post_into_bucket;
  return api.post(url, model);
};

export const getRelatedArticles = ({ articleId, locale }) => {
  const url = API_ROUTES.get_relative_posts + `${articleId}/?locale=${locale}`;
  return api.get(url);
};

export const getArticleDetail = (model) => {
  const url =
    API_ROUTES.get_article_detail +
    `${model.post_name}/?locale=${model.locale}`;

  return api.get(url);
};

export const searchArticles = (model) => {
  const url = API_ROUTES.search_articles;
  return api.get(url, { params: model });
};

export const loginWithFacebook = (model) => {
  const url = API_ROUTES.login_with_facebook;
  return api.get(url, { params: model });
};

export const loginWithGoogle = (model) => {
  const url = API_ROUTES.login_with_google;
  return api.get(url, { params: model });
};

export const getArticlesByTagOrCategory = (model) => {
  const tagUrl = API_ROUTES.get_articles_by_tag;
  const tagModel = {
    page: model.page,
    limit: model.limit,
    tag_name: model.name,
    locale: model.locale,
  };

  const categoryUrl = API_ROUTES.get_articles_by_category;
  const categoryModel = {
    page: model.page,
    limit: model.limit,
    category_slug: model.name,
    destination_slug: model.destinationSlug,
    locale: model.locale,
  };

  const url = model.isCategory ? categoryUrl : tagUrl;

  return api.get(url, {
    params: model.isCategory ? categoryModel : tagModel,
  });
};

export const getSitemap = () => {
  const url = API_ROUTES.get_sitemap;
  return api.get(url);
};

export const postExternalLink = (model) => {
  const url = API_ROUTES.post_external_link;
  return api.post(url, model);
};

export const trackingArticleListing = (model) => {
  const url = API_ROUTES.tracking_article_listing;
  return api.post(url, model);
};

export const trackingBrandedPlacement = (model) => {
  const url = API_ROUTES.tracking_branded_placement;
  return api.post(url, model);
};

export const trackingExternalLink = (model) => {
  const url = API_ROUTES.tracking_external_link;
  return api.post(url, model);
};

export const trackingClickUrlOnBrandedPlacement = (model) => {
  const url = API_ROUTES.tracking_click_url_on_branded_placement;
  return api.post(url, model);
};

export const getDestinationDetail = ({ country, city }) => {
  const url = API_ROUTES.get_destination_detail;
  return api.get(url, { params: { country_slug: country, city_slug: city } });
};

export const getAllCategories = () => {
  const url = API_ROUTES.get_all_categories;
  return api.get(url);
};

export const getHomepageData = () => {
  const url = API_ROUTES.get_homepage_data;
  return api.get(url);
};

export const getLatestArticles = (locale) => {
  const url = API_ROUTES.get_latest_articles + `?locale=${locale}`;
  return api.get(url);
};

export const getDestinations = (locale) => {
  const url = API_ROUTES.get_destinations + `?locale=${locale}`;
  return api.get(url);
};

export const removeFavouritedBlogPage = (blogPageId) => {
  const url = API_ROUTES.remove_favourited_blogpage + `${blogPageId}/`;
  return api.delete(url);
};

export const getHomepageHeaderData = (locale) => {
  const url = API_ROUTES.get_homepage_header_data + `?locale=${locale}`;
  return api.get(url);
};

export const getHomepageBodyData = () => {
  const url = API_ROUTES.get_homepage_body_data;
  return api.get(url);
};

export const getLandingPageData = (model) => {
  const url = API_ROUTES.get_landing_page_data;
  return api.get(url, { params: model });
};
